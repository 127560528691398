import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BatchesList from '../components/BatchesList';
import ConfirmEnrollment from '../components/ConfirmEnrollment';
import ViewEnrollment from '../components/ViewEnrollments';
import CourseList from '../components/CourseList';
import LoginPage from '../components/Login';
import RegistrationForm from '../components/RegistrationForm';
import FillDetails from '../components/FillDetails';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/filldetail/:currentUserId" element={<FillDetails />} />
        <Route path="/courses/:userId" element={<CourseList />} />
        <Route path="/courses/batch/:courseId" element={<BatchesList />} />
        <Route path="/registration/complete/:enrollmentId" element={<ConfirmEnrollment />} />
        <Route path="/registration/enrollments/:userId" element={<ViewEnrollment />} />

      </Routes>
    </Router>
  );
};

export default AppRouter;
