import React, { useEffect, useState } from 'react';
import '../index.css';
import '../css/Enrollment.css';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import QRCode from 'react-qr-code';
import ReactLoading from 'react-loading';



const ViewEnrollment = () => {

    const { userId } = useParams();
    const [enrollment, setEnrollment] = useState({});
    const [enrollmentId, setEnrollmentId] = useState({});
    const [enrollmentIds, setEnrollmentIds] = useState([]);
    const [user, setUser] = useState({});
    const [batches, setBatches] = useState({});
    const [isLoading, setLoading] = useState(true);

    const navigate = useNavigate();

    const handleLogout = () => {
        console.log("Logging Out");
        auth.signOut()
            .then(() => {
                navigate('/login');
            })
            .catch((error) => {
                // Handle logout error
                console.log('Logout error:', error);
            });
        localStorage.clear();
    };

    const saveAsPDF = () => {
        window.print()
    }

    const fetchUser = async () => {

        const querySnapshot = await firestore.collection('users').doc(userId).get();

        if (querySnapshot.exists) {
            const userData = querySnapshot.data();
            const { email, firstName, lastName } = userData;

            const userObject = {
                email: email,
                userId: userId,
                firstName: firstName,
                lastName: lastName
            };


            setUser(userObject);
        } else {
            console.log('User not found');
        }

    };


    useEffect(() => {
        const fetchEnrollment = async () => {
            try {
                const querySnapshot = await firestore.collection('enrollments').where('enrolledBy', '==', userId).get();
                if (!querySnapshot.empty) {
                    console.log(querySnapshot.docs);
                    console.log("querySnapshot.docs[0].id");
                    console.log(querySnapshot.docs[0].id);

                    const enrollmentIds = querySnapshot.docs.map(doc => doc.id);
                    setEnrollmentIds(enrollmentIds);

                    const enrollments = querySnapshot.docs.map(doc => doc.data());
                    console.log('Enrollments:', enrollments);
                    setEnrollmentId(querySnapshot.docs[0].id);
                    setEnrollment(enrollments);
                    let _bathes = enrollments.flatMap((e) => e.batches);
                    console.log(_bathes);
                    setBatches(_bathes)
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchUser();
        fetchEnrollment();

    }

        , []);
    // });

    //return '';

    if (isLoading) return <ReactLoading className='spinner' type={'spin'} color={'black'} height={30} width={30} />;

    return (
        enrollment.length > 0 && batches.length > 0 &&
        <div className="enrollment-body">
            <div className="logo">
                <img src="https://gocbe-lms-astc.b-cdn.net/assets/Logo/Image/7514e432-bd05-4d34-a7ed-3697c150a1d1logo-1.jpg" />
            </div>
            <div className="container">
                <h4>Congratulations you have succesfully registered at ASTC.</h4>
            </div>
            <div className="container">
                
                 <h2 className="heading">{user.firstName} {user.lastName}</h2>
                
                <div>
                    <label>User ID: {userId}</label>
                    <label>Enrollments ({enrollmentIds.length}): {enrollmentIds.join(' | ')}</label>
                </div>
                <div>
                    <label>{user.email}</label>
                </div>
                <div style={{ height: "auto", margin: "20px auto", maxWidth: 128, width: "100%" }}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={enrollmentId}
                        viewBox={`0 0 256 256`}
                    />
                </div> 

                <div>
                    {/* <button onClick={handleLogout}>Logout</button> */}
                    {
                        <button onClick={saveAsPDF}>Save PDF</button>
                    }
                </div>
                <br />
                <table className="enrollment-table styled-table">
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Course</th>
                            <th>Batch</th>
                            <th>Teacher</th>
                            <th>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            batches?.map((e, idx) => {
                                return (
                                    <tr>
                                        <td>{idx + 1}</td>
                                        <td>{e.courseTitle}</td>
                                        <td>{e.batchTitle}</td>
                                        <td>{e.trainer}</td>
                                        <td>{e.price.toLocaleString()}</td>
                                    </tr>
                                );
                            })
                        }

                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">Total Amount:</td>
                            <td>PKR {batches?.reduce((accumulator, object) => accumulator + object.price, 0).toLocaleString()}</td>
                        </tr>
                    </tfoot>
                </table>
                <h4>Your account will be created on ASTC LMS and you will be updated within 24 to 48 hours via email.</h4>
                <h4>Please note that your seat will be confirmed after you have paid the fee (WITHIN 2 DAYS, ELSE YOUR SEAT WILL BE LAPSED) which can be paid either in Cash or through IBFT (Bank Transfer). For futher details, click on the following options.</h4>
            </div>
            <div className='enrollment-footer'>
                <a href="https://gocbe-lms-astc.b-cdn.net/assets/cash_payment.html?v=2" target="_blank" className="batch-back-btn">Cash Payment</a>
                <a href="https://gocbe-lms-astc.b-cdn.net/assets/ibft.html?v=2" target="_blank" className="batch-back-btn">IBFT (Bank Transfer)</a>
            </div>
        </div>
    );
};

export default ViewEnrollment;
