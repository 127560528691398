import React, { useEffect, useState } from 'react';
import '../css/CourseList.css';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import ReactLoading from 'react-loading';



const CourseList = () => {

  const { userId } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [haveEnrollments, setHaveEnrollments] = useState('');
  const [tempuserId, setUserId] = useState('');
  const [qualificationsData, setQualificationsData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  //   const courses = [
  //     'O Level',
  //     'AS Level',
  //     'A2 Level',
  //     'Accelerated A levels',
  //     'Temporary Access',
  //   ];

  // const courses = [
  //   { id: 1, name: 'O Level' },
  //   { id: 2, name: 'AS Level' },
  //   { id: 3, name: 'A2 Level' },
  //   { id: 4, name: 'Accelerated A levels' },
  //   { id: 5, name: 'Temporary Access' },
  // ];

  const navigate = useNavigate();


  const handleLogout = () => {
    console.log("Logging Out");
    auth.signOut()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        // Handle logout error
        console.log('Logout error:', error);
      });
    localStorage.clear();
  };

  const viewEnrollments = async () => {
    console.log("userID");
    console.log(userId);
    navigate(`/registration/enrollments/${userId}`);

    // const querySnapshot = await firestore.collection('enrollments').where('enrolledBy', '==', userId).get();
    // console.log(querySnapshot.docs.length)
    // if (!querySnapshot.empty && querySnapshot.docs.length >0) {
    //     navigate(`/registration/enrollments/${querySnapshot.docs[0].id}`);
    // }
    // else{
    //   alert("No Enrollments.")
    // }

    // navigate(`/registration/complete/${querySnapshot.docs.id}`);
  }

  const checkEnrollments = async () => {
    const querySnapshot = await firestore.collection('enrollments').where('enrolledBy', '==', userId).get();
    if (!querySnapshot.empty) {
      console.log(querySnapshot.docs);
      const enrollments = querySnapshot.docs.map(doc => doc.data());
      setHaveEnrollments(enrollments.length > 0)
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        console.log("USER FOUND");
        const _userId = user.uid;
        setUserId(user.uid);

        // Retrieve the user document from Firestore
        firestore
          .collection('users')
          .doc(_userId)
          .get()
          .then((doc) => {
            if (doc.exists && doc.data().email) {
              // Get the user data from the document
              const userData = doc.data();
              const firstName = userData.firstName;
              const lastName = userData.lastName;
              setFirstName(firstName);
              setLastName(lastName);

              // Use the retrieved data as needed
              console.log('First Name:', firstName);
              console.log('Last Name:', lastName);
            }
            // If User Details are not available
            else {
              console.log("User Not Found");
              const currLoggedInUserEmail = localStorage.getItem('currentEmail');
              const currLoggedInUserPassword = localStorage.getItem('currentPassword');

              if(currLoggedInUserEmail && currLoggedInUserPassword) {
                navigate(`/filldetail/${userId}`);
              } else {
                handleLogout();
              }
            }
          })
          .catch((error) => {
            console.log('Error retrieving user data:', error);
          });

        const fetchQualificationsData = async () => {
          try {
            //const docRef = firestore.collection('courses').doc('puXgYvqEqUMCGmJLjYF8');
            const docRef = firestore.collection('courses');
            const querySnapshot = await docRef.limit(1).get();
            const doc = querySnapshot.docs[0];
            //console.log(doc.docs[0].data());
            if (doc.exists) {
              console.log("Got Data");
              let qualificationsOrdered = doc.data().qualifications.sort((a, b) => a.sequence - b.sequence);
              setQualificationsData(qualificationsOrdered);
              setLoading(false);
              console.log(doc.data().qualifications);
            } else {
              console.log('No such document!');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        // const fetchQualificationsData = async () => {
        //   try {

        //       //const qualifications = require('https://gocbe-lms-astc.b-cdn.net/assets/qualifications.json');

        //       const qualificationsJson = await fetch('https://gocbe-lms-astc.b-cdn.net/assets/qualifications.json');
        //       const qualifications = await qualificationsJson.json();
        //       console.log("Got Data");
        //       console.log(typeof qualifications);
        //       let qualificationsOrdered = qualifications.qualifications.sort((a, b) => a.sequence - b.sequence);
        //       setQualificationsData(qualificationsOrdered);

        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // };

        checkEnrollments();
        fetchQualificationsData();
      } else {
        // User is not logged in
        // Handle the case when the user is not logged in
        navigate('/login');
      }
    })
  }

    , []);
  // });



  return (
    !isLoading ?
      (<div className="course-body">

        <div className="user-detail-row">
          <div className="container">

            <div className="user-detail">
              <div>
                <h3 className="courses-heading">Welcome {firstName} {lastName} </h3>
                {/* <h1 className="courses-heading">UserID: {tempuserId}</h1>  */}
              </div>

              <div>
                <button onClick={handleLogout}>Logout</button>
              </div>
            </div>

          </div>
        </div>

        <div className="banner-small">
          <div className="container d-flex">
            <div className="banner-title-card">
              <h1 className="banner-title">Course Registration</h1>
            </div>

          </div>
        </div>

        <div className="container">
          {haveEnrollments ? <button className="batch-back-btn" onClick={viewEnrollments}>View My Enrollments</button> : <span></span>}
        </div>

        <div class="container">

          {/* <br /> */}

          <div className="courses-page-container">
            <h2 className="qualification-heading">Select Qualification</h2>
            <br />


            <div className="courses-grid">
              {/* {courses.map((course, index) => (
            <div className="course-card" key={index}>
              {course}
            </div>
          ))} */}
              {qualificationsData.map((qualification) => (
                <Link
                  key={qualification.id}
                  to={`/courses/batch/${qualification.id}`}
                  // params={ tempuserId }
                  state={{ userId: tempuserId }}
                  className="course-card"
                >
                  {qualification.title}
                </Link>
              ))}

            </div>

          </div>

        </div>
      </div>) : (<ReactLoading className='spinner' type={'spin'} color={'black'} height={30} width={30} />)
  );
};

export default CourseList;
