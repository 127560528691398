import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import '../css/BatchesList.css';
import { auth, firestore } from '../firebase';
import ReactLoading from 'react-loading';


const BatchesList = () => {
  const [isLoading, setLoading] = useState(true);
  const [isQualificationLoading, setQualificationLoading] = useState(true);


  const { courseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const { userId } = location.state

  const [jsonData, setJsonData] = useState([]);
  var   [totalPrice, setTotalPrice] = useState(0);
  const [selectedBatches, setSelectedBatches] = useState({});
  const [currentCourses, setCurrentCourses] = useState({});
  let [enrolledCourses, setEnrolledCourses] = useState([]);

  const [cart, setCart] = useState([]);
  const [user, setUser] = useState({});

  //let enrolledCourses = [];


  const handleBatchClick = (batchId, courseId, courseTitle, batch) => {
    const courseInCart = cart.find((item) => item.courseId === courseId);
    if (!courseInCart) {
      setSelectedBatches((prevState) => ({
        ...prevState,
        [courseId]: batchId,
      }));
    }
  };

  const isSelected = (batchId, courseId) => {
    return selectedBatches[courseId] === batchId;
  };

  const isDisabled = (batchId, courseId) => {
    const courseInCart = cart.find((item) => item.courseId === courseId);
    return courseInCart && courseInCart.batchId === batchId;
  };

  const handleAddToCart = (courseId, courseTitle) => {

    if(enrolledCourses.some((e) => e.courseId == courseId)) {
      alert('You cannot enroll already enrolled course.');
      return;
    }

    const batchId = selectedBatches[courseId];
    if (batchId) {
      const batch = jsonData.courses
        .find((course) => course.id === courseId)
        .batches.find((batch) => batch.id === batchId);
      setTotalPrice(totalPrice += batch.price)
      // setCart((prevCart) => [...prevCart, { courseId, courseTitle, batchId, batch }]);

      setCart((prevCart) => {
        const newCart = [...prevCart, { courseId, courseTitle, batchId, batch }];
        localStorage.setItem('cart', JSON.stringify(newCart));
        return newCart;
      });

      setSelectedBatches((prevState) => ({
        ...prevState,
        [courseId]: null,
      }));
    }
    console.log("ADD to cart");
    console.log(cart);
    // localStorage.removeItem('cart');
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const handleRemoveFromCart = (courseId, batchId, batchPrice) => {
    setTotalPrice(totalPrice -= batchPrice)
    // setCart((prevCart) => prevCart.filter((item) => item.courseId !== courseId || item.batchId !== batchId));
    setCart((prevCart) => {
      const newCart = prevCart.filter((item) => item.courseId !== courseId);
      localStorage.setItem('cart', JSON.stringify(newCart));
      return newCart;
    });
    console.log("Remove cart");
    console.log(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const handleEnroll = async () => {
    setLoading(true);
    console.log(cart);
    const tempdata =
    {
      timestamp: Date.now(),
      createdAt: new Date(),
      enrolledBy: userId,
      user,
      batches: cart.map((item) => ({
        courseId: item.courseId,
        courseTitle: item.courseTitle,
        batchId: item.batch.id,
        batchTitle: item.batch.title,
        trainer: item.batch.trainer || "N/A",
        price: item.batch.price
      }))
    };
    console.log("tempdata");
    console.log(tempdata);

    try {
      const docRef = firestore.collection('enrollments');
      await docRef.add(tempdata).then((docRef) => {
        console.log('Data appended successfully with ID:', docRef.id);
        localStorage.clear();

        setLoading(false);
        navigate(`/registration/complete/${docRef.id}`);
      });
      console.log('Data added to Firestore successfully!');

    } catch (error) {
      setLoading(false);
      console.error('Error adding data to Firestore:', error);
    }

    cart.map((batch) => updateBatchSeats(batch.batch.id));
    console.log("Updated");
  }

  const updateBatchSeats = async (batchID) => {
    console.log("updateBatchSeats");
    //const documentRef = firestore.collection('courses').doc('puXgYvqEqUMCGmJLjYF8');
    const documentRef = firestore.collection('courses');

    try {
      const snapshot = await documentRef.limit(1).get();
      const documentSnapshot = snapshot.docs[0];
      if (documentSnapshot.exists) {
        console.log("EXISTS");
        const documentData = documentSnapshot.data();
        console.log(documentData);
        console.log(documentData.qualifications);

        documentData.qualifications.forEach((q) => {
          q.courses.forEach((course) => {

            console.log('course 1');
            console.log(course);
            console.log(course.batches);

            course.batches?.forEach((batch) => {
              if (batch.id === batchID) {
                console.log("batch found");
                batch.currentSeatsAvailable = batch.currentSeatsAvailable - 1;
              }
            });
          });
        });
        await documentSnapshot.ref.update({
          qualifications: documentData.qualifications,
        });

        console.log('Seat count updated successfully');
      } else {
        console.log('Document does not exist');
      }
    } catch (error) {
      console.error('Error updating seat count:', error);
    }
  }

  useEffect(() => {

    auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      }
    });

    const cartItems = JSON.parse(localStorage.getItem('cart'));
    if (cartItems) {
      setCart(cartItems);
    }
    const fetchUserData = async ()=>{
      firestore
      .collection('users')
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Get the user data from the document
          const userData = doc.data();
          console.log("userData");
          console.log(userData);
          setUser(userData);
        }});
    }

    // const fetchData = async () => {
    //   try {
    //     //const docRef = firestore.collection('courses').doc('puXgYvqEqUMCGmJLjYF8');
    //     const docRef = firestore.collection('courses');
    //     const querySnapshot = await docRef.limit(1).get();
    //     const doc = querySnapshot.docs[0];
        
    //     if (doc.exists) {
    //       console.log("Got Data");
    //       setJsonData(doc.data().qualifications.find(q => q.id == courseId));
    //       setCurrentCourses(doc.data().qualifications.find(q => q.id == courseId));
    //       console.log(doc.data().qualifications.find(q => q.id == courseId));          
    //     } else {
    //       console.log('No such document!');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    const fetchData = async () => {
      try {
        const docRef = firestore.collection('courses').doc('hiJBflXuKtjkzCVigMeX');

        //      const docRef = firestore.collection('courses');
        // const querySnapshot = await docRef.limit(1).get();
    
        docRef.onSnapshot((snapshot) => {
          if (snapshot.exists) {
            console.log('Got Data');
            console.log(snapshot.data());
            console.log(snapshot.data().qualifications);
            console.log('snapshot.data().qualifications');
            // console.log(snapshot.data().qualifications[0].id);
            let temp = snapshot.data().qualifications.find((q) => { 
              console.log(q.id);
              console.log(courseId);
              return q.id == courseId})
            console.log(123=="123");
            console.log("temp");
            console.log(temp);


            console.log(snapshot.data().qualifications.find((q) => q.id == courseId));
            setJsonData(snapshot.data().qualifications.find((q) => q.id == courseId));
            setCurrentCourses(snapshot.data().qualifications.find((q) => q.id === courseId));
            setQualificationLoading(false);
            console.log(snapshot.data().qualifications.find((q) => q.id == courseId));
            console.log(jsonData);
          } else {
            console.log('No such document!');
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    // const fetchData = async () => {
    //   try {

    //     const doc = require('https://gocbe-lms-astc.b-cdn.net/assets/final_2.json');



    //       console.log("Got Data");
    //       // setJsonData(doc.data().qualifications.find(q => q.id == courseId));
    //       // setCurrentCourses(doc.data().qualifications.find(q => q.id == courseId));
    //       // console.log(doc.data().qualifications.find(q => q.id == courseId));          
        
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    const fetchUserEnrollments = async () => {
      try{
        //const docRef = firestore.collection('enrollments');
        //const query = firestore.query(docRef, firestore.where('user.id', '==', user.id));
        console.log({userId});
        const querySnapshot = await firestore.collection('enrollments').where("enrolledBy", "==", userId).get();

        console.log(querySnapshot.docs);

        const enrollmentsData = querySnapshot.docs.map((doc) => {
          console.log(doc.data());
          return doc.data()
        });

        //enrolledCourses = enrollments;
        let enrollments = [];
        enrollmentsData.forEach((ed) => {
          console.log('ed');
          console.log(ed.batches.map((b) => b));
          ed.batches.forEach((b) => {
            enrollments.push(b);
          });

        });

        //enrolledCourses = enrollments.map((e) => e.batches);

        console.log(enrollments.map((e) => e.batches).map((b) => b));
        
        setEnrolledCourses(enrollments);

        console.log({enrolledCourses});

      }
      catch (error) {
        console.error('Error fetching user enrollments:', error)
      }
    }
    setLoading(true);
    fetchUserData().then(() => {
      setLoading(true);
      fetchData().then(() => {
        console.log("jsonData");
        console.log(jsonData);

        console.log("fetching user enrollments");
        fetchUserEnrollments();
      }).finally(() => {
        setLoading(false);
      });
    }).finally(() => {
      setLoading(false);
    }); 
  }, [courseId]);

  console.log("cart");
  console.log(cart);

  // const unsubscribe = firestore.collection('courses')
  //     .onSnapshot((querySnapshot) => {
  //       console.log(querySnapshot.docs[0].ref);
  //       //setJsonData(querySnapshot.docs[0]);
  //       // // Handle changes in the collection
  //       // querySnapshot.forEach((doc) => {
  //       //   // Process individual documents
  //       //   const data = doc.data();
  //       //   console.log('real time update');
  //       //   console.log(data);

  //       //   // Perform actions with the document data
  //       // });
  //     });

  return (
    <div className="batch-body">

      <div className="banner-small">
        <div className="container d-flex">
          <div className="banner-title-card">
            <h1 className="banner-title">Course Registration</h1>
          </div>         

        </div>
      </div>  

      <div className="container">

        
        <div className="batch-heading-btn">
          <div className="batch-back-btn">
            <button onClick={() => navigate(`/courses/${userId}`)}>Back</button>
            {/* <br />
            <br />
            <button onClick={() => console.log(cart)}>Print Cart</button> */}
          </div>
          <div className="batch-qualification-heading">
            {/* <p>User: {userId}</p> */}
            {/* <h2>{currentCourses.title}</h2> */}
          </div>
        </div>

        <div className="course-reg-container">

          <div className="batch-list-container">
            {/* <h2>Course Details</h2> */}
            { isQualificationLoading ?  (<ReactLoading className='mid-spinner' type={'spin'} color={'black'} height={30} width={30} />)
            : jsonData ? (
              <div>
                {jsonData &&
                  <div className="subject-grid">
                    {jsonData.courses &&
                      jsonData.courses.map((course) => (
                        <div className="subject-card">
                          <h4>{course.title}</h4>
                          {
                            enrolledCourses.map((b) => {
                              if(b.courseId == course.id) {

                                return (
                                  <div>
                                    <p style={{color: "red"}}>You are already enrolled in this course.</p>
                                  </div>
                                )
                              }
                          })}
                          <div style={
                          enrolledCourses.some((b) => {
                            return b.courseId == course.id;
                        }) ? {pointerEvents: 'auto', opacity: 0.5} : {}}>
                          
                          {course.batches &&
                            course.batches.map((batch) => (
                              <div className="batch-container">
                                <label className="batch-checkbox">
                                  {/* <input type="radio" name={course.id} onClick={() => handleBatchClick(batch.id, course.id, course.title, batch)}
                                    checked={isSelected(batch.id, course.id)}
                                    disabled={isDisabled(batch.id, course.id)} /> */}
                                  <input
                                    type="checkbox"
                                    className="batch-checkbox_input"
                                    name={course.id}
                                    onClick={() => handleBatchClick(batch.id, course.id, course.title, batch)}
                                    checked={isDisabled(batch.id, course.id) || isSelected(batch.id, course.id) || enrolledCourses.some((e) => e.batchId == batch.id)}
                                    disabled={batch.currentSeatsAvailable <= 0 || isDisabled(batch.id, course.id)}
                                  />
                                  <span class="batch-checkbox_control"></span>
                                </label>
                                <div key={batch.id} className={isDisabled(batch.id, course.id) ? "batch-card-disable" : "batch-card"}>
                                  <h5>{batch.title}</h5>
                                  <p className="batch-desc text-muted" dangerouslySetInnerHTML={{ __html: `<p>${batch.description}</p>` }} />
                                  <p>by <span className="trainer-name">{batch.trainer}</span></p>
                                  <p className="batch-price-text">
                                    {batch.currencyCode} {batch.price.toLocaleString()}
                                  </p>
                                  {batch.currentSeatsAvailable >= 0 && <p className="batch-seats-label">
                                    Seats left {batch.currentSeatsAvailable}/{batch.maximumSeatsAvailable}
                                  </p>}

                                  {/* <p>
                                    {batch.currentSeatsAvailable >=0 ? "Available" : "Out of stock"}
                                  </p> */}
                                  {batch.currentSeatsAvailable <= 0 &&
                                    
                                    <p style={{color: "red"}}> 
                                      This batch is full. Please select any other batch
                                    </p>
                                  }
                                </div>
                              </div>
                            ))}
                            </div>
                          <div>
                            <div className="batch-add-btn">
                              {!selectedBatches[course.id] && (
                                <button
                                  onClick={() => handleAddToCart(course.id, course.title)}
                                  disabled={true}
                                  className="added-btn"
                                >
                                  Add
                                </button>
                              )}
                              {selectedBatches[course.id] && (
                                <button
                                  onClick={() => handleAddToCart(course.id, course.title)}
                                  disabled={isDisabled(selectedBatches[course.id], course.id)}
                                >
                                  Add
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                }
              </div>
            ) : (
              <p>No courses available...</p>
            )}
          </div>

          <div className="cart-container">
            <div className="cart-inner">
              <div>
                <p className="top-label">
                  Total Amount
                </p>                
                  <h4 className="total-amount">
                    PKR {totalPrice.toLocaleString()}
                  </h4>
                

                <p className="selected-batches-text">
                  Selected Batches
                </p>
              </div>

              {cart.length > 0 ? (
                <div className="selected-batches-wrapper">
                  <ul className="selected-batches">
                    {cart.map((item, index) => (
                      <li className="selected-batch-container">
                        <div className="selected-batch-card">
                          <div className="selected-batch-headings">
                            <h3>
                              {item.courseTitle}
                            </h3>
                            <h4>
                              {item.batch.title}
                            </h4>
                            <h4>
                              {item.batch.trainer}
                            </h4>
                          </div>
                          
                        </div>
                        <div className="selected-batch-card">
                          <div className="selected-batch-headings">
                            
                            <p dangerouslySetInnerHTML={{ __html: `<p>${item.batch.description}</p>` }} />
                            <h4>
                              {item.batch.currencyCode} {item.batch.price.toLocaleString()}
                            </h4>
                            <div className="batch-back-btn">
                              <button onClick={() => handleRemoveFromCart(item.courseId, item.batchId, item.batch.price)}>Remove</button>
                            </div>
                          </div>
                          <div>
                          
                            </div>
                          
                        </div>
                      </li>
                    ))}
                  </ul>
                  <button onClick={() => handleEnroll()} disabled={isLoading}>{isLoading ? "Please wait..." : "Confirm Registration"}</button>
                </div>
              ) : (
                <p>No courses have been selected.</p>
              )}
            </div>
          </div>

        </div>


      </div>
    </div>

  );
  //unsubscribe();
};

export default BatchesList;
