import React, { useEffect, useState } from 'react';
import '../index.css';
import '../css/Enrollment.css';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import QRCode from 'react-qr-code';
import ReactLoading from 'react-loading';



const ConfirmEnrollment = () => {

  const { enrollmentId } = useParams();

  const [enrollment, setEnrollment] = useState({});
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleLogout = () => {
    console.log("Logging Out");
    auth.signOut()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        // Handle logout error
        console.log('Logout error:', error);
      });
    localStorage.clear();
  };

  const saveAsPDF = () =>{
    window.print()
  }


  useEffect(() => {
    const fetchEnrollment = async () => {
      try {
        const docRef = firestore.collection('enrollments').doc(enrollmentId);
        const doc = await docRef.get();
        if (doc.exists) {
          console.log("Got Data");

          setEnrollment(doc.data());
          setLoading(false);
          console.log(doc.data());

          //console.log(enrollment.user.name);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEnrollment();
  }

    , []);
  // });

  //return '';

  if(isLoading) return <ReactLoading className='spinner' type={'spin'} color={'black'} height={30} width={30} />;

  return (
    enrollment && enrollment.user && enrollment.batches &&
    <div className="enrollment-body">
      <div className="logo">
        <img src="https://gocbe-lms-astc.b-cdn.net/assets/Logo/Image/7514e432-bd05-4d34-a7ed-3697c150a1d1logo-1.jpg" />
      </div>
      <div className="container">
        <h4>Congratulations you have succesfully registered at ASTC.</h4>
      </div>
      <div className="container">
      <h2 className="heading">{enrollment.user.firstName} {enrollment.user.lastName}</h2>
      <div>
        <label>Enrollment ID: {enrollmentId}</label>
      </div>
      <div>
        <label>{enrollment.user.email}</label>
      </div>
      <div style={{ height: "auto", margin: "20px auto", maxWidth: 128, width: "100%" }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={enrollmentId}
          viewBox={`0 0 256 256`}
        />
      </div>
   
      <div>
        {/* <button onClick={handleLogout}>Logout</button> */}
        {
        <button onClick={saveAsPDF}>Save PDF</button> 
        }
      </div>
      <br />
          <table className="enrollment-table styled-table">
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Course</th>
            <th>Batch</th>
            <th>Teacher</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>
          {
            enrollment?.batches?.map((e, idx) => {
              return (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{e.courseTitle}</td>
                  <td>{e.batchTitle}</td>
                  <td>{e.trainer}</td>
                  <td>{e.price.toLocaleString()}</td>
                </tr>
              );
            })
          }

        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">Total Amount:</td>
            <td>PKR {enrollment?.batches?.reduce((accumulator, object) => accumulator + object.price, 0).toLocaleString()}</td>
          </tr>
        </tfoot>
      </table>
      <h4>Your account will be created on ASTC LMS and you will be updated within 24 to 48 hours via email.</h4>
      <h4>Please note that your seat will be confirmed after you have paid the fee (WITHIN 2 DAYS, ELSE YOUR SEAT WILL BE LAPSED) which can be paid either in Cash or through IBFT (Bank Transfer). For futher details, click on the following options.</h4>
      </div>
      <div className='enrollment-footer'>
        <a href="https://gocbe-lms-astc.b-cdn.net/assets/cash_payment.html?v=2" target="_blank" className="batch-back-btn">Cash Payment</a>
        <a href="https://gocbe-lms-astc.b-cdn.net/assets/ibft.html?v=2" target="_blank" className="batch-back-btn">IBFT (Bank Transfer)</a>
      </div>
    </div>
  );
};

export default ConfirmEnrollment;
