import React, { useEffect, useState } from 'react';
import { auth,firestore } from '../firebase';
import '../index.css';
import '../css/LoginForm.css';
import { Link,useNavigate } from 'react-router-dom';


const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState('');

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // const jsonData = require('../final_5.json');
  // const jsonData = require('../astc-1.json');

  // const jsonData = 
  //   {
  //     "qualifications": [
  //       {
  //         "id": 1234,
  //         "title": "O Levels",
  //         "sequence": 1,
  //         "courses": [
  //           {
  //             "id": 4567,
  //             "title": "Accounting",
  //             "sequence": 1,
  //             "batches": [
  //               {
  //                 "id": 7891,
  //                 "title": "Batch 1",
  //                 "description": "<p>Mon 3 to 4:30pm</p><p>Tue 4:30 to 6pm</p>",
  //                 "trainer": "Ahmed Saya",
  //                 "maximumSeatsAvailable": 150,
  //                 "currentSeatsAvailable": 140,
  //                 "currencyCode": "PKR",
  //                 "price": 4000.00,
  //                 "sequence": 1
  //               } 
  //             ]
  //           } ,
  //             {
  //             "id": 4593,
  //             "title": "Maths",
  //             "sequence": 1,
  //             "batches": [
  //               {
  //                 "id": 7894,
  //                 "title": "Batch 3",
  //                 "description": "<p>Mon 3 to 4:30pm</p><p>Tue 4:30 to 6pm</p>",
  //                 "trainer": "Ahmed Saya",
  //                 "maximumSeatsAvailable": 150,
  //                 "currentSeatsAvailable": 140,
  //                 "currencyCode": "PKR",
  //                 "price": 4000.00,
  //                 "sequence": 1
  //               } 
  //             ]
  //           } 
  //         ]
  //       },
  //       {
  //         "id": 1235,
  //         "title": "A Levels",
  //         "sequence": 1,
  //         "courses": [
  //           {
  //             "id": 5567,
  //             "title": "Chemistry",
  //             "sequence": 1,
  //             "batches": [
  //               {
  //                 "id": 9894,
  //                 "title": "Batch 1",
  //                 "description": "<p>Mon 3 to 4:30pm</p><p>Tue 4:30 to 6pm</p>",
  //                 "trainer": "Ahmed Saya",
  //                 "maximumSeatsAvailable": 150,
  //                 "currentSeatsAvailable": 140,
  //                 "currencyCode": "PKR",
  //                 "price": 4000.00,
  //                 "sequence": 1
  //               }  ,
  //               {
  //                 "id": 9899,
  //                 "title": "Batch 2",
  //                 "description": "<p>Mon 3 to 4:30pm</p><p>Tue 4:30 to 6pm</p>",
  //                 "trainer": "Ahmed Saya",
  //                 "maximumSeatsAvailable": 150,
  //                 "currentSeatsAvailable": 150,
  //                 "currencyCode": "PKR",
  //                 "price": 4000.00,
  //                 "sequence": 2
  //               }
  //             ]
  //           } ,
  //                   {
  //             "id": 5568,
  //             "title": "English",
  //             "sequence": 1,
  //             "batches": [
  //               {
  //                 "id": 8894,
  //                 "title": "Batch 2",
  //                 "description": "<p>Mon 3 to 4:30pm</p><p>Tue 4:30 to 6pm</p>",
  //                 "trainer": "Ahmed Saya",
  //                 "maximumSeatsAvailable": 150,
  //                 "currentSeatsAvailable": 140,
  //                 "currencyCode": "PKR",
  //                 "price": 4000.00,
  //                 "sequence": 1
  //               }  
  //             ]
  //           } 
  //         ]
  //       }
        
  //     ]
    
  // };

  // const addDataToFirestore = async (data) => {
  //   console.log("ADDING");
  //   try {
  //     const docRef = firestore.collection('courses').doc('hiJBflXuKtjkzCVigMeX');
  //     // await docRef.add(data).then((docRef) => {
  //     //       console.log('Data appended successfully with ID:', docRef.id);
  //     //     });
  //     await docRef.set(data);
  //     console.log('Data added to Firestore successfully!');
  //   } catch (error) {
  //     console.error('Error adding data to Firestore:', error);
  //   }
  // };
  

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      // Sign in with email and password
      await auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
        // Retrieve the user's first name and last name from Firestore
        // Assuming you have stored the user's name in Firestore during signup
        setUserId(userCredential.user.uid);
        setLoading(false);
        // Clear the form fields
        setEmail('');
        setPassword('');
        console.log(userId);
  
        // Redirect to the desired page
        // e.g., history.push('/dashboard');
        console.log("Navigating from here");
        localStorage.setItem('currentEmail', email);
        localStorage.setItem('currentPassword', password);
        navigate(`/courses/${userCredential.user.uid}`);
        
      });
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    if(window.location.host != "registeration-react-app.pages.dev" && window.location.host != 'localhost:3000') {
      const displayNotice = () => { 
        var elemDiv = document.createElement("div");
        elemDiv.style.cssText = "text-align: center;top:0;left:0;bottom:0;right:0;position: fixed;background-color: white;z-index:99999;overflow: hidden;display: flex;text-align: center;align-items: center;font-size: 1.75rem;color:#000;justify-content: center;font-weight: 600;";
        elemDiv.innerHTML = "<div style='padding: 20px 45px;color: #000;border-radius: 12px;font-size: 1.75rem;background: #fff;box-shadow: 0 2px 10px #ddd;'><div><img src='https://gocbe-lms-astc.b-cdn.net/assets/astc-registration-2024-25-banner-compressed.jpg' alt='image' height='350'></div></div>"; 
        document.body.appendChild(elemDiv); 
      }     
      var timeAt12pm = new Date("06/05/2024 11:59:59 AM")
      var timeNow = new Date();

      if(timeNow.getTime() < timeAt12pm.getTime()){
        //setTimeout(displayNotice, 200);
      }
    }

    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("user");
        console.log(user.uid);
        navigate(`/courses/${user.uid}`);
      }
    });
  });

  return (
    <div className="login-body">
      <div className="logo">
        <img src="https://gocbe-lms-astc.b-cdn.net/assets/Logo/Image/7514e432-bd05-4d34-a7ed-3697c150a1d1logo-1.jpg" />
      </div>
    <div className="login-form-container">
      <h2>Login</h2>
      {error && <div className="error-message">{error.replace("Firebase: ", "")}</div>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div className="form-group">
          <button type="submit" className="custom-btn" disabled={isLoading}>{isLoading ? "Please wait..." : "Login"}</button>
        </div>
      </form>
      <div className="signup-link">
        Don't have an account?{' '}
        <br/>
        <br/>
        <Link to="/register" className="custom-btn">Create New Account to Register</Link>
      </div>
    </div>

    {/* <button className="add-json-data-btn" onClick={()=>addDataToFirestore(jsonData)}>Add Data</button> */}

    </div>

  );
};

export default LoginForm;
